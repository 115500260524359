import React, {useEffect} from "react";
import useLocalStorage from "./useLocalStorage";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import {baseWebsite} from "./utils";
import Toolbar from "@material-ui/core/Toolbar";

export default function Header() {
  const [user, setUser] = useLocalStorage('chess-user', '')
  const [token, setToken] = useLocalStorage('chess-token', '')
  const buttonStyle = {width: '19%'}
  useEffect(() => {
    if (!token.length) {
      document.location = `${baseWebsite}/login`
    }
  }, [])
  return (
    <div>
      <AppBar color="primary" position="static">
        <Toolbar>
          Federal Chess Federation
          <Button color='inherit' style={buttonStyle} onClick={() => {
            document.location = baseWebsite
          }}>
            Current Games
          </Button>
          <Button color='inherit' style={buttonStyle} onClick={() => {
            document.location = `${baseWebsite}/challenges/`
          }}>
            Challenges
          </Button>
          <Button color='inherit' style={buttonStyle} onClick={() => {
            document.location = `${baseWebsite}/leaderboard/`
          }}>
            Leaderboard
          </Button>
          <Button color='inherit' style={buttonStyle} onClick={() => {
            document.location = `${baseWebsite}/login/`
            setToken('')
            setUser('')
          }}>
            Logout
          </Button>
          {user}
        </Toolbar>
      </AppBar>
    </div>
  )
}