import React from "react";
import Paper from "@material-ui/core/Paper";

export default function Clock({seconds, player, rating}) {
  const container = {
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  }
  const paperStyles = {
    height: 50,
    width: 100,
    fontSize: 40
  }
  const width2 = {
    width: '30%',
    fontSize:20
  }
  const width1 = {
    width: '10%'
  }
  return (
    <span style={container}>
      <span style={width1}/>
      <span style={width2}>
      <span>
        {player} ({rating})
      </span>
      </span>
      <Paper style={paperStyles}>
        {(seconds - seconds % 60) / 60}:{seconds % 60 >= 10 ? seconds % 60 : '0' + JSON.stringify(seconds % 60)}
      </Paper>
    </span>
  )
}