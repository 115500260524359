import {HashRouter as Router, Route} from "react-router-dom";
import React from "react";
import Login from "./Login";
import Signup from "./Signup";
import Home from "./Home";
import IssueChallenge from "./IssueChallenge";
import Challenges from "./Challenges";
import Game from "./Game";
import LeaderBoard from "./LeaderBoard";
function Main() {
  return (
    <Router>
      <Route exact path={'/'}>
        <Home/>
      </Route>
      <Route exact path={'/login/'}>
        <Login/>
      </Route>
      <Route exact path={'/signup/'}>
        <Signup/>
      </Route>
      <Route exact path={'/issue/'}>
        <IssueChallenge/>
      </Route>
      <Route exact path={'/leaderboard/'}>
        <LeaderBoard/>
      </Route>
      <Route exact path={'/challenges/'}>
        <Challenges/>
      </Route>
      <Route path={'/game/'}>
        <Game/>
      </Route>
    </Router>
  );
}

export default Main;