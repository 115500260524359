import React, {useEffect, useState} from "react";
import WithMoveValidation from "./WithMoveValidation";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite} from "./utils";
import Chess from 'chess.js'
import Clock from "./Clock";
import {Button} from "@material-ui/core";
import Header from "./Header";

function Game() {
  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const id = document.location.href.split('/:')[1]
  const [token, setToken] = useLocalStorage('chess-token', '')
  const [tick, setTick] = useState(0)
  const [fen, setFen] = useState('start')
  const [rating, setRating] = useState(1200)
  const [opponentRating, setOpponentRating] = useState(1200)
  const [spec, setSpec] = useState(false)
  const [game, setGame] = useState({
    players: ['', ''],
    turn: 0,
    times: [0, 0],
    pgn: '',
    drawOffered: 2,
    inProgress: true,
    winner: 2,
    seconds: 0,
    movesMade: 0,
    createdBy: ''
  })
  const resultStyles = {
    fontSize: 50
  }
  useEffect(() => {
    async function getGame() {
      const game1 = (await axios.post(`${baseServer}/games/getGame`, {id: id}, {headers: {token: token}})).data
      const chess = new Chess()
      chess.load_pgn(game1._doc.pgn)
      setFen(chess.fen())
      setGame(game1)
      if (tick===0) {
        if (game1.myColor < 0) {
          setSpec(true)
          console.log('spec')
          const newRating = (await axios.post(`${baseServer}/user/getRating`, {username: game1._doc.players[0]})).data
          setRating(newRating)
          const oppRating = (await axios.post(`${baseServer}/user/getRating`, {username: game1._doc.players[1]})).data
          setOpponentRating(oppRating)
        } else {
          const data1= {username: game1._doc.players[game1.myColor]}
          const newRating = (await axios.post(`${baseServer}/user/getRating`, data1)).data
          setRating(newRating)
          const oppRating = (await axios.post(`${baseServer}/user/getRating`, {username: game1._doc.players[(game1.myColor + 1) % 2]})).data
          setOpponentRating(oppRating)
        }
      }
    }

    setTimeout(() => {
        getGame()
        setTick(tick + 1)
      }, 100
    )
  }, [tick])
  const boardsContainer = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  };
  const buttonContainer = {
    padding: 10
  }
  const buttonStyle = {
    backgroundColor: '#1976d2',
    color: '#fff'
  }
  const copyContainer = {
    padding: 20
  }
  const heightStyle = {
    height: 20
  }
  return (
    <div>
      {game._doc && game._doc.winner !== 2 ? <Header/> : null}
      {!spec ?
        <Clock seconds={game._doc ? game._doc.times[(game.myColor + 1) % 2] : 0}
               player={game._doc ? game._doc.players[(game.myColor + 1) % 2] : null} rating={opponentRating}/>
        :
        <Clock seconds={game._doc ? game._doc.times[1] : 0}
               player={game._doc ? game._doc.players[1] : null} rating={opponentRating}/>
      }
      <div style={boardsContainer}>
        <WithMoveValidation orientation={game.myColor && !spec ? 'black' : 'white'} pos={fen}
                            handle={async (from, to, newFen) => {
                              setFen(newFen)
                              await axios.post(`${baseServer}/games/move`, {
                                gameId: game._doc._id,
                                move: {from: from, to: to}
                              }, {headers: {token: token}})
                            }} movable={!spec ? game.myTurn : false}/>
        <div>
          {!spec ?
            <div>
              {game._doc && game._doc.inProgress ?
                <div>
              <span style={buttonContainer}>
            <Button style={buttonStyle} onClick={async () => {
              await axios.post(`${baseServer}/games/resign`, {gameId: game._doc._id}, {headers: {token: token}})
            }}>
              resign
            </Button>
          </span>
                  {
                    game._doc.drawOffered === 2 ?
                      <span style={buttonContainer}>
                  <Button style={buttonStyle} onClick={async () => {
                    await axios.post(`${baseServer}/games/offerDraw`, {gameId: game._doc._id}, {headers: {token: token}})
                  }}>
                    offer draw
                  </Button>
                  </span>
                      : game._doc.drawOffered !== game.myColor ?
                      <span>
                    <span style={buttonContainer}>
                  <Button style={buttonStyle} onClick={async () => {
                    await axios.post(`${baseServer}/games/acceptDraw`, {gameId: game._doc._id}, {headers: {token: token}})
                  }}>
                    accept draw
                  </Button>
                    </span>
                    <span style={buttonContainer}>
                  <Button style={buttonStyle} onClick={async () => {
                    await axios.post(`${baseServer}/games/declineDraw`, {gameId: game._doc._id}, {headers: {token: token}})
                  }}>
                    decline draw
                  </Button>
                    </span>
                  </span> : null
                  }
                  {
                    game._doc.movesMade<2 && !spec?(
                      <span>
                    <span style={buttonContainer}>
                  <Button style={buttonStyle} onClick={async () => {
                    await axios.post(`${baseServer}/challenges/declineChallenge`, {gameId: game._doc._id}, {headers: {token: token}})
                    document.location = baseWebsite
                  }}>
                    abort
                  </Button>
                    </span>
                  </span>
                      )
                      : null
                  }
                </div>
                : <span style={resultStyles}>
              {game._doc ? game._doc.winner === 0.5 ? '1/2-1/2 Draw' : game._doc.winner === 1 ? '0-1 Black Wins' : '1-0 White Wins' : null}
            </span>}
            </div> :
            null
          }
          <div>
            <div style={heightStyle}/>
            <span style={copyContainer}>
          <Button onClick={() => {
            copyToClipboard(document.location.href)
          }}>
            copy spectate link
          </Button>
            </span>
            <span style={copyContainer}>
          <Button onClick={() => {
            copyToClipboard(game._doc.pgn)
          }}>
            copy notation
          </Button>
            </span>
          </div>
        </div>
      </div>
      {!spec ?
        <Clock seconds={game._doc ? game._doc.times[game.myColor] : 0}
               player={game._doc ? game._doc.players[game.myColor] : null} rating={rating}/>
        :
        <Clock seconds={game._doc ? game._doc.times[0] : 0}
               player={game._doc ? game._doc.players[0] : null} rating={rating}/>
      }
    </div>
  );
}

export default Game;
