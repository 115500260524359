import React, {useState} from "react";
import useLocalStorage from "./useLocalStorage";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import axios from "axios";
import {baseServer, baseWebsite} from "./utils";
import Header from "./Header";
import SearchIcon from '@material-ui/icons/Search';

function IssueChallenge() {
  const [list, setList] = useState([])
  const [search, setSearch] = useState('')
  const [token, setToken] = useLocalStorage('chess-token', '')
  const [selected, setSelected] = useState('')
  const [color, setColor] = useState(0)
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')
  const [user,setUser] = useLocalStorage('chess-user','')
  const timeFieldStyle = {
    width: 70,
    height: 60
  }
  const padStyle = {
    padding: 20
  }
  const exceptionStyle = {
    backgroundColor: '#888'
  }
  const plusStyle = {
    fontSize: 30
  }
  const buttonStyle = {
    backgroundColor: "#1976d2",
    color: "#fff",
  };
  const container = {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: '40%'
  }


  return (
    <div>
      <Header/>
      <div style={container}>
        <div style={padStyle}>
          <TextField label={'Search for a player'} onChange={async event => {
            setSearch(event.target.value)
            const userList = (await axios.post(`${baseServer}/challenges/listUsers`, {search: event.target.value}, {headers: {token: token}})).data
            setList(userList)
          }}/>
          <SearchIcon/>
        </div>
        <div style={padStyle}>
          {
            list.filter(elem=>elem.username!==user).map(elem => (
              <div>
                {elem.username === selected ?
                  <Button style={exceptionStyle} onClick={() => {
                    setSelected(elem.username)
                    console.log(list)
                  }}>
                    {elem.username} {elem.rating}
                  </Button>
                  :
                  <Button onClick={() => {
                    setSelected(elem.username)
                  }}>
                    {elem.username} {elem.rating}
                  </Button>
                }
              </div>
            ))
          }
        </div>
        <div style={padStyle}>
          Pick Your Color
          <div style={padStyle}>
            <Button style={!color ? exceptionStyle : null} onClick={() => {
              setColor(0)
            }}>
              White
            </Button>

            <Button style={color ? exceptionStyle : null} onClick={() => {
              setColor(1)
            }}>
              Black
            </Button>
          </div>
          Timeframe:
          <div style={padStyle}>
            <TextField style={timeFieldStyle} label={'Minutes'} type={'number'} onChange={event => {
              if(event.target.value>=0){setMinutes(event.target.value)}
            }} value={minutes}/>
            {'    '}
            <span style={plusStyle}>+</span>
            {'    '}
            <TextField style={timeFieldStyle} label={'Increment'} type={'number'} onChange={event => {
              if(event.target.value>=0){setSeconds(event.target.value)}
            }} value={seconds}/>
          </div>
          <div style={padStyle}>
            <Button style={buttonStyle} onClick={async () => {
              const payload = {timeFrame: [JSON.parse(minutes), JSON.parse(seconds)], color: color, opponent: selected}
              await axios.post(`${baseServer}/challenges/makeChallenge`, payload, {headers: {token: token}})
              document.location = `${baseWebsite}/challenges`
            }}>
              Issue Challenge
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IssueChallenge