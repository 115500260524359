import useLocalStorage from "./useLocalStorage";
import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {baseServer, baseWebsite} from "./utils";
import axios from 'axios'
import Button from "@material-ui/core/Button";
import Header from "./Header";

function Home() {
  const [token, setToken] = useLocalStorage('chess-token', '')
  const [games, setGames] = useState([])
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 300,
      width: 300,
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

  const pawnStyles = {height: 150, width: 150, align: 'center', margin: 'auto', justifyContent: 'center'}
  const paperStyles = {justifyContent: 'center'}
  const classes = useStyles();
  const fontStyle = {fontSize: 20}
  const buttonStyle = {width: 300, height: 300}
  const head = {fontSize: 40,padding:40}

  useEffect(() => {
    async function getGames() {
      const games = (await axios.get(`${baseServer}/challenges/joinable`, {headers: {token: token}})).data
      if (games.error) {
        document.location = `${baseWebsite}/login/`
      }
      setGames(games)
    }

    getGames()
  }, [])
  return (
    <div>
      <Header/>
      <div style={head}>
        Current Games
      </div>
      <div>
        <Grid item xs={10}>
          <Grid container justify="center" spacing={5}>
            {games.map((elem) => (
              <Grid>
                <Paper className={classes.paper} style={paperStyles}>
                  <Button style={buttonStyle} onClick={()=>{
                    document.location=`${baseWebsite}/game/:${elem.id}`
                  }}>
                    {elem.myColor ? <img style={pawnStyles}
                                         src="https://cdn.shopify.com/s/files/1/2209/1363/products/e9efdc8da24d3a804f91fb02d900660fb8f4e01611ed4060a943097a5ce16802_1024x1024@2x.jpg?v=1604642664"
                                         alt=""/> :
                      <img style={pawnStyles}
                           src="https://img2.cgtrader.com/items/869509/ff264eddac/chess-pieces-pawn-white-3d-model-max-obj-3ds-fbx.jpg"
                           alt=""/>}
                    <div style={fontStyle}>
                      {elem.timeFrame} vs {elem.opponent}
                    </div>
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Home