import React, {Component, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Chess from "chess.js"; // import Chess from  "chess.js"(default) if recieving an error about new Chess() not being a constructor

import Chessboard from "chessboardjsx";
import axios from "axios";
import {baseServer} from "./utils";
import useLocalStorage from "./useLocalStorage";

class HumanVsHuman extends Component {
  static propTypes = {
    children: PropTypes.func,
    pos: PropTypes.string,
    handle: PropTypes.func,
    movable: PropTypes.bool,
    key: PropTypes.string
  };
  state = {
    fen: 'start',
    // square styles for active drop square
    dropSquareStyle: {},
    // custom square styles
    squareStyles: {},
    // square with the currently clicked piece
    pieceSquare: "",
    // currently clicked square
    square: "",
    // array of past game moves
    history: []
  };

  componentDidMount() {
    this.game = new Chess();
  }

  onDrop = ({sourceSquare, targetSquare}) => {
    // see if the move is legal
    if (this.props.movable) {
      this.game = new Chess(this.props.pos)
      console.log(this.game.fen())
      let move = this.game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q" // always promote to a queen for example simplicity
      });

      // illegal move
      if (move === null) {
        console.log('illegal')
        return
      }

      this.props.handle(sourceSquare, targetSquare, this.game.fen())

      this.setState(({history, pieceSquare}) => ({
        fen: this.game.fen(),
        history: this.game.history({verbose: true}),
      }));
    }
  };


  onSquareClick = square => {
    if (this.props.movable) {
      this.game = new Chess(this.props.pos)
      this.setState(({history}) => ({
        pieceSquare: square
      }));

      let move = this.game.move({
        from: this.state.pieceSquare,
        to: square,
        promotion: "q" // always promote to a queen for example simplicity
      });

      // illegal move
      if (move === null) {
        console.log('illegal')
        return
      }
      this.props.handle(this.state.pieceSquare, square, this.game.fen())
      this.setState({
        fen: this.game.fen(),
        history: this.game.history({verbose: true}),
        pieceSquare: ""
      });
    }
  };


  render() {
    const {dropSquareStyle, squareStyles} = this.state;

    return this.props.children({
      squareStyles,
      position: this.props.pos,
      onMouseOverSquare: this.onMouseOverSquare,
      onMouseOutSquare: this.onMouseOutSquare,
      onDrop: this.onDrop,
      dropSquareStyle,
      onDragOverSquare: this.onDragOverSquare,
      onSquareClick: this.onSquareClick,
    });
  }
}

export default function WithMoveValidation({handle, pos, orientation, showNotation, movable}) {
  return (
    <div>
      <HumanVsHuman pos={pos} handle={handle} movable={movable}>
        {({
            position,
            onDrop,
            onMouseOutSquare,
            dropSquareStyle,
            onSquareClick,
            onSquareRightClick
          }) => (<Chessboard
            id="humanVsHuman"
            width={600}
            onDrop={onDrop}
            position={position}
            onMouseOutSquare={onMouseOutSquare}
            boardStyle={{
              borderRadius: "5px",
              boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`
            }}
            dropSquareStyle={dropSquareStyle}
            onSquareClick={onSquareClick}
            onSquareRightClick={onSquareRightClick}
            orientation={orientation}
            showNotation={showNotation}
          />
        )}
      </HumanVsHuman>
    </div>
  );
}