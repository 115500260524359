import React, {useState, useEffect} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite} from "./utils";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Header from "./Header";

function Challenges() {
  const [token, setToken] = useLocalStorage('chess-token', '')
  const [challenges, setChallenges] = useState({canAccept: [], cantAccept: []})
  const sectionPadding = {
    padding:40
  }
  const acceptStyle = {
    backgroundColor:'green',
    color:'#fff'
  }
  const declineStyle = {
    backgroundColor:'red',
    color:'#fff'
  }
  useEffect(() => {
    async function start() {
      const challengeArr = (await axios.get(`${baseServer}/challenges/myChallenges`, {headers: {token: token}})).data
      setChallenges({
        cantAccept: challengeArr.filter(elem => elem.createdByYou),
        canAccept: challengeArr.filter(elem => !elem.createdByYou)
      })
    }

    start()
  }, [])
  return (
    <div>
      <Header/>
      <div style={sectionPadding}>
        Incoming Challenges:
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Opponent
              </TableCell>
              <TableCell>
                Time Frame
              </TableCell>
              <TableCell>
                Your Color
              </TableCell>
              <TableCell>
                <Button color={'primary'} variant={'contained'} onClick={()=>{document.location=`${baseWebsite}/issue`}}>
                  Issue a Challenge
                </Button>
              </TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {challenges.canAccept.map(elem => <TableRow>
              <TableCell>
                {elem.opponent}</TableCell>
              <TableCell>{elem.timeFrame} </TableCell>
              <TableCell>{elem.yourColor ? 'Black' : 'White'}</TableCell>
              <TableCell>
                <Button variant="contained" style={acceptStyle} onClick={async () => {
                  await axios.post(`${baseServer}/challenges/acceptChallenge`, {gameId: elem.id}, {headers: {token: token}})
                  document.location=`${baseWebsite}/game/:${elem.id}`
                }}>
                  accept
                </Button>
              </TableCell>
              <TableCell>
                <Button style={declineStyle} onClick={async () => {
                  await axios.post(`${baseServer}/challenges/declineChallenge`, {gameId: elem.id}, {headers: {token: token}})
                  window.location.reload()
                }}>
                  decline
                </Button>
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>
      <div style={sectionPadding}>
        Pending Challenges:
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Opponent
              </TableCell>
              <TableCell>
                Time Frame
              </TableCell>
              <TableCell>
                Your Color
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {challenges.cantAccept.map(elem => <TableRow>
              <TableCell>
                {elem.opponent}</TableCell>
              <TableCell>{elem.timeFrame} </TableCell>
              <TableCell>{elem.yourColor ? 'Black' : 'White'}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default Challenges