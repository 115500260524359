import React, {useEffect, useState} from 'react'
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {baseServer, baseWebsite} from "./utils";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import Header from "./Header";

function LeaderBoard() {
  const [ranks, setRanks] = useState([])
  const [distinct, setDistinct] = useState([])
  const titleStyle = {
    fontSize: 30,
    padding: 20
  }
  useEffect(() => {
    async function getRanks() {
      const ranks = (await axios.get(`${baseServer}/user/leaderboard`)).data
      setRanks(ranks)
      let different = []
      ranks.forEach(elem => {
        if (!different.includes(elem.rating)) {
          different.push(elem.rating)
        }
      })
      setDistinct(different)
    }

    getRanks()
  }, [])
  return (
    <div>
      <Header/>
      <div style={titleStyle}>
        Rankings:
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Rank
            </TableCell>
            <TableCell>
              Player
            </TableCell>
            <TableCell>
              Rating
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ranks.map((elem, idx) =>
            <TableRow>
              <TableCell>
                {ranks.findIndex(elm=>elm.rating===elem.rating)+1}
              </TableCell>
              <TableCell>{elem.username} </TableCell>
              <TableCell>
                {elem.rating}
              </TableCell>
            </TableRow>)}
        </TableBody>
      </Table>
    </div>
  )
}

export default LeaderBoard