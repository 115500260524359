import React, {useState} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite} from "./utils";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

function Signup() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginFail, setLoginFail] = useState(false)
  const [token, setToken] = useLocalStorage('chess-token', '')
  const [user,setUser] = useLocalStorage('chess-user','')
  const padStyle = {
    padding: 20
  }
  const mainStyle = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }
  const fontStyle = {
    fontStyle:'Georgia'
  }
  return (
    <div style={mainStyle}>
      <div style={fontStyle}>
        Signup
      </div>
      <div style={padStyle}>
        <TextField label={'Username'} type="text" onChange={event => {
          setUsername(event.target.value)
        }} value={username}/>
      </div>
      <div style={padStyle}>
        <TextField label={'Password'} type="password" onChange={event => {
          setPassword(event.target.value)
        }} value={password}/>
      </div>
      <div style={padStyle}>
        <Button color={'primary'} variant={'contained'} onClick={async () => {
          if (password.length>6) {
            const newTok = (await axios.post(`${baseServer}/user/signup`, {
              username: username,
              password: password
            })).data
            if (newTok.token.length > 30) {
              setLoginFail(false)
              setToken(newTok.token)
              setUser(newTok.username)
              document.location = baseWebsite
            }
          } else {
            setLoginFail(true)
          }
        }}>
          Sign Up
        </Button>
      </div>
      <div>
        {loginFail?'Password must be at least 7 characters':null}
      </div>
    </div>
  );
}

export default Signup;